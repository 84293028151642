import bg from '../../../../../static/images/loader/background.png';
import bgImage from './carousel-bg.png';

export const extendMainContainer = () => ({
    container: ({ theme: { colors } }) => ({
        backgroundColor: colors.darkBlue,
        backgroundImage: `url(${bg})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'repeat',
    }),
});

export const extendShapesContainer = () => ({
    container: () => ({
        marginTop: '-2.5rem',
    }),
});

export const extendInnerContainer = () => ({
    container: () => ({
        backgroundImage: `url(${bgImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '90% 2rem',

        paddingBottom: '16rem',
    }),
});

export const extendSingleStepWrapper = () => ({
    container: () => ({
        overflow: 'visible',
    }),
});

export const extendSingleStep = () => ({
    container: () => ({
        width: '80%',
        borderLeft: 'none',
    }),
});

export const extendWorkshopOrderContainer = () => ({
    container: () => ({
        marginTop: 0,
        paddingTop: 0,
        paddingBottom: 0,
        tabletLarge: {
            paddingTop: 0,
            paddingBottom: 0,
        },

        transform: 'translateY(-50%)',
    }),
});

export const extendTitle = {
    title: () => ({
        fontSize: '3rem',
        maxWidth: '38.25rem',
    }),
};

export const extendText = {
    text: () => ({
        maxWidth: '36.25rem',
    }),
};

export const extendVideo = {
    container: () => ({
        position: 'relative',
        width: '100%',
        top: 'unset',
        right: 'unset',

        tabletLarge: {
            width: '100%',
        },
    }),
};

export const divider = () => ({
    height: '6rem',

    tabletLarge: {
        height: '8rem',
    },
});

export const dividerMiddle = ({ theme }) => ({
    ...divider({ theme }),
    marginTop: '3rem',
});

export const ackeeLogo = () => ({
    maxWidth: '30rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '3rem',

    tablet: {
        marginLeft: 0,
        marginRight: 'auto',
    },

    tabletLarge: {
        marginTop: '0rem',
    },
});
