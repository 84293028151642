import bg from '../../../../../static/images/loader/background.png';
import bgImage from '../AboutSection/carousel-bg.png';

export const extendMainContainer = () => ({
    container: ({ theme: { colors } }) => ({
        backgroundColor: colors.darkBlue,
        backgroundImage: `url(${bg})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'repeat',
    }),
});

export const extendInnerContainer = () => ({
    container: () => ({
        backgroundImage: `url(${bgImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '90% 2rem',

        paddingBottom: 0,

        tabletLarge: {
            paddingBottom: '11rem',
        },
    }),
});

export const extendSingleStepWrapper = () => ({
    container: () => ({
        overflow: 'visible',
    }),
});

export const extendSingleStep = () => ({
    container: () => ({
        tablet: {
            width: '80%',
        },
        borderLeft: 'none',
        marginBottom: '5rem',
    }),
});

export const extendWorkshopOrderContainer = () => ({
    container: () => ({
        marginTop: '-6rem',
        paddingTop: 0,
        paddingBottom: 0,

        tabletLarge: {
            marginTop: 0,
            paddingTop: 0,
            paddingBottom: 0,
            transform: 'translateY(-50%)',
        },
    }),
});

export const extendTitle = {
    title: ({ theme }) => ({
        color: theme.colors.white,
        marginTop: 0,

        marginBottom: '4rem',
        tablet: {
            marginBottom: '8rem',
        },
    }),
};
