export const imageContainer = () => ({
    width: '20rem',
    maxWidth: '70%',
    top: '15rem',
    right: '2.5rem',
    position: 'absolute',
    zIndex: -2,
    display: 'none',

    tablet: {
        display: 'block',
    },

    tabletLarge: {
        width: '28rem',
        top: '12.5rem',
        right: '2.5rem',
    },

    desktopLarge: {
        top: '10rem',
        width: '34rem',
        right: '5rem',
    },

    desktopFullHD: {
        top: '10%',
        width: '35rem',
        right: '5%',
    },

    '@media (min-width: 2000px)': {
        top: '18%',
        width: '38rem',
        right: '10%',
    },
});
