import fill from 'lodash/fill';
import { React, PropTypes, UI, FormattedMessage } from '../../dependencies';

const { DynamicContainer, ContainerType, Text, TextTypes } = UI;

const logosImages = fill(Array(11)).map((_, i) => ({
    src: `/campaign-logos/${i + 1}.svg`,
}));

const CooperationSection = ({ rules, styles }) => {
    return (
        <DynamicContainer
            extend={rules.extendContainer}
            as={ContainerType.SECTION}
            type={ContainerType.GRID}
            columns={12}
            isFluid
        >
            <Text extend={rules.extendTitle} type={TextTypes.SECTION} as="h3" columns={12}>
                <FormattedMessage id="campaignPage.cooperation.title1" />
            </Text>
            <DynamicContainer type={ContainerType.GRID} columns={12} extend={rules.extendLogoWrapper}>
                {logosImages.map(logo => (
                    <DynamicContainer columns={2} key={logo.src} extend={rules.extendLogoContainer}>
                        <img src={logo.src} className={styles.logo} alt="" />
                    </DynamicContainer>
                ))}
            </DynamicContainer>
        </DynamicContainer>
    );
};

CooperationSection.propTypes = {
    rules: PropTypes.shape({}).isRequired,
    styles: PropTypes.shape({}).isRequired,
};

export default CooperationSection;
