//  this cannot be imported from dependencies
import { graphql, useStaticQuery } from 'gatsby';

import { React, PropTypes, Img } from '../../dependencies';

const Image = ({ styles, alt }) => {
    const { file } = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "campaign/hero.png" }) {
                childImageSharp {
                    fluid(quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
        }
    `);

    return (
        <div className={styles.imageContainer}>
            <Img alt={alt} className={styles.image} fluid={file.childImageSharp.fluid} />
            {/* {description && <span className={styles.description}>description</span>} */}
        </div>
    );
};

Image.propTypes = {
    styles: PropTypes.shape().isRequired,
    alt: PropTypes.string.isRequired,
};

export default Image;
