import React from 'react';

import { Layout } from '../modules/ui';
import { CampaignPage as CampaignPageInner } from '../modules/campaign-page';
import Seo from '../components/SEO';
import * as Constants from '../constants';

import socialPreview from '../../static/social-previews/campaign_share.png';

const CampaignPage = () => {
    const footerRef = React.useRef();

    return (
        <>
            <Seo pageId={Constants.pages.CAMPAIGN} image={socialPreview} />
            <Layout footerRef={footerRef}>
                <CampaignPageInner footerRef={footerRef} />
            </Layout>
        </>
    );
};

export default CampaignPage;
