import Image from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import { React, PropTypes, UI, FormattedMessage, HeroVideo } from '../../dependencies';
const PrototypeVideo = 'https://storage.googleapis.com/ackee-web-static/videos/heroes/design_prototype.mp4';

const { DynamicContainer, ContainerType, LazyLoadContainer, Title, Text, TextTypes, TitleTypes } = UI;

const AboutSection = ({ rules, styles }) => {
    const { file } = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "campaign/ackee_logo.png" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 480) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    return (
        <DynamicContainer as={ContainerType.SECTION} isFluid type={ContainerType.GRID}>
            {/* Section 1 */}
            <DynamicContainer columns={6}>
                <Title extend={rules.extendTitle} as={TitleTypes.H2} type={TitleTypes.H1}>
                    <FormattedMessage id="campaignPage.about.title1" />
                </Title>
                <Text extend={rules.extendText} type={TextTypes.EXTRA_LARGE}>
                    <FormattedMessage id="campaignPage.about.description1" />
                </Text>
            </DynamicContainer>
            <DynamicContainer columns={5} startAt={8}>
                <LazyLoadContainer offset={0} lazyLoaded>
                    <Image className={styles.ackeeLogo} fluid={file.childImageSharp.fluid} />
                </LazyLoadContainer>
            </DynamicContainer>

            <DynamicContainer columns={12}>
                <div className={styles.dividerMiddle} />
            </DynamicContainer>

            {/* Section 2 */}
            <DynamicContainer columns={6} startAt={2} extend={rules.extendShapesContainer}>
                <LazyLoadContainer offset={0} lazyLoaded>
                    <HeroVideo extend={rules.extendVideo} src={PrototypeVideo} />
                </LazyLoadContainer>
            </DynamicContainer>
            <DynamicContainer columns={5}>
                <Text extend={rules.extendText} type={TextTypes.EXTRA_LARGE}>
                    <FormattedMessage id="campaignPage.about.description2" />
                </Text>
            </DynamicContainer>
        </DynamicContainer>
    );
};

AboutSection.propTypes = {
    rules: PropTypes.shape({}).isRequired,
    styles: PropTypes.shape({}).isRequired,
};

export default AboutSection;
