import { React, HeroSection, FormattedMessage, UI } from '../../dependencies';

import HeroImage from '../HeroImage';
import RequirementsSection from '../RequirementsSection';
import AboutSection from '../AboutSection';
import CooperationSection from '../CooperationSection';
import ImpressumSection from '../ImpressumSection';

const { TitleTypes } = UI;

const CampaignPage = ({ footerRef, rules }) => {
    return (
        <>
            <HeroSection
                title={<FormattedMessage id="campaignPage.hero.title" htmlType="span" />}
                description={<FormattedMessage id="campaignPage.hero.description" htmlType="span" />}
                titleAs={TitleTypes.H1}
                customContent={<HeroImage alt="" />}
            />
            <RequirementsSection />
            <AboutSection />
            <CooperationSection />
            <ImpressumSection />
        </>
    );
};

export default CampaignPage;
