//  this cannot be imported from dependencies
import { graphql, useStaticQuery } from 'gatsby';

import { React, PropTypes, Img } from '../../../dependencies';

const Image = ({ styles, src, alt }) => {
    const { file } = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "campaign/wworkshops.png" }) {
                childImageSharp {
                    fluid(quality: 90, maxHeight: 376) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
        }
    `);

    return (
        <div className={styles.container}>
            <Img alt="" fluid={file.childImageSharp.fluid} />
        </div>
    );
};

Image.propTypes = {
    styles: PropTypes.shape().isRequired,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
};

export default Image;
