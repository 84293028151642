export const dangerousContainer = () => ({
    fontSize: '1.125rem',

    '> div > ul > li': {
        marginLeft: '2rem',
        lineHeight: '1.5rem',
    },

    '> div > ol > li': {
        marginBottom: '2rem',
        paddingLeft: '1rem',
        lineHeight: '2rem',
    },
});

export const link = ({ theme: { colors } }) => ({
    color: colors.blue,
    fontSize: '1.125rem',
    display: 'inline',
    textDecoration: 'underline',

    transition: 'cubic-bezier(0.4, 0.0, 0.2, 1) color 0.3s',
    willChange: 'color',

    ':hover': {
        color: colors.fuchsia,
    },
});
