export const extendContainer = () => ({
    container: () => ({
        borderTop: `1px solid #D8D8D8`,
        paddingTop: '1.5rem',

        tabletLarge: {
            paddingTop: '1.5rem',
        },

        desktopFullHD: {
            paddingLeft: 0,
        },
    }),
});

export const extendLogoWrapper = () => ({
    container: () => ({
        gridTemplateColumns: 'repeat(10, 1fr)',
        gridColumnGap: '0.5rem',
        alignItems: 'center',

        tabletLarge: {
            gridTemplateColumns: 'repeat(10, 1fr)',
            gridColumnGap: '0.5rem',
            alignItems: 'center',
        },
    }),
});

export const extendLogoContainer = () => ({
    container: () => ({
        minHeight: '6.25rem',
        display: 'flex',
    }),
});

export const extendTitle = {
    text: () => ({
        fontSize: '1rem',
        maxWidth: '38.25rem',
    }),
};

export const logo = () => ({
    marginBottom: '2.5rem',
    tabletLarge: {
        marginBottom: 0,
    },
});
