export const extendContainer = {
    container: () => ({
        paddingTop: 0,
        flexDirection: 'column',

        tabletLarge: {
            flexDirection: 'row',
        },
    }),
};

export const descriptionContainer = ({ theme: { colors } }) => ({
    width: '100%',
    backgroundColor: `#F6F6F6`,
    color: colors.black,
    padding: '2.5rem',
    boxSizing: 'border-box',

    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',

    tabletLarge: {
        width: '45%',
    },
});

export const extendTitle = {
    title: ({ theme: { colors } }) => ({
        fontSize: '2rem',
        marginTop: 0,
        marginBottom: '0.75rem',
        lineHeight: '2.75rem',

        backgroundColor: `#F6F6F6`,
        color: colors.black,
    }),
};

export const extendText = {
    text: ({ theme: { colors } }) => ({
        color: colors.black,
        marginBottom: '2.0625rem',
    }),
};

export const textLink = ({ theme: { colors } }) => ({
    color: colors.cyan,
    ':visited': {
        color: colors.cyan,
    },
});

export const link = ({ theme: { colors } }) => ({
    color: colors.blue,
    background: 'none',
    border: 'none',
    fontSize: '2rem',
    fontWeight: 'bold',
    padding: 0,
    transition: 'cubic-bezier(0.4, 0.0, 0.2, 1) color 0.3s, cubic-bezier(0.4, 0.0, 0.2, 1) border-color 0.3s',

    ':hover': {
        color: colors.fuchsia,
        borderColor: 'transparent',
    },
});
