export const container = () => ({
    width: '100%',
    boxSizing: 'border-box',

    tabletLarge: {
        width: '55%',

        '> div': {
            height: '100%',
        },
    },

    desktop1200: {
        maxHeight: '23.5rem',
    },
});
