import { React, PropTypes, UI, FormattedMessage } from '../../dependencies';
import WorkshopOrder from '../WorkshopOrder';

const workshopOrderContent = {
    title: 'campaignPage.workshop.order.title',
    description: 'campaignPage.workshop.order.description',
    cta: 'campaignPage.workshop.order.cta',
    image: 'analysis/workshop-section/office-workshop.jpg',
    imageAlt: 'campaignPage.workshop.order.imageAlt',
};

const { DynamicContainer, ContainerType, SingleStep, Title, TitleTypes } = UI;

const content = Array.from(Array(5).keys()).map(id => ({
    id,
    serviceName: `campaignPage.services.slide${id + 1}.menuItem`,
    title: `campaignPage.services.slide${id + 1}.title`,
}));

const RequirementsSection = ({ rules, styles }) => {
    return (
        <>
            <DynamicContainer as={ContainerType.SECTION} extend={rules.extendMainContainer}>
                <DynamicContainer extend={rules.extendInnerContainer}>
                    <DynamicContainer columns={12} type={ContainerType.GRID} as={ContainerType.SECTION} isFluid>
                        <Title extend={rules.extendTitle} as={TitleTypes.H2} type={TitleTypes.H2} columns={12}>
                            <FormattedMessage id="campaignPage.services.title" />
                        </Title>
                        {content.map(value => (
                            <DynamicContainer key={value.id} columns={4} extend={rules.extendSingleStepWrapper}>
                                <SingleStep content={value} key={value.title} extend={rules.extendSingleStep} />
                            </DynamicContainer>
                        ))}
                    </DynamicContainer>
                </DynamicContainer>
            </DynamicContainer>
            <DynamicContainer columns={12} isFluid extend={rules.extendWorkshopOrderContainer}>
                <WorkshopOrder content={workshopOrderContent} />
            </DynamicContainer>
        </>
    );
};

RequirementsSection.propTypes = {
    rules: PropTypes.shape({}).isRequired,
    styles: PropTypes.shape({}).isRequired,
};

export default RequirementsSection;
