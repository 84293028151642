import { React, PropTypes, UI, FormattedMessage, useIntl } from '../../dependencies';
import Agreement from '../Agreement';

const { DynamicContainer, ContainerType, Text, TextTypes, Title, TitleTypes } = UI;

const ImpressumSection = ({ rules, styles }) => {
    const intl = useIntl();
    const constets = intl.formatMessage({ id: 'campaignPage.impressum.content' });

    return (
        <DynamicContainer
            extend={rules.extendContainer}
            as={ContainerType.SECTION}
            type={ContainerType.GRID}
            columns={12}
            isFluid
        >
            <DynamicContainer columns={12} extend={rules.extendInnerContainer}>
                <Title type={TitleTypes.H4} as={TitleTypes.H2} extend={rules.extendTitle}>
                    <FormattedMessage id="campaignPage.impressum.title" />
                </Title>
            </DynamicContainer>
            <DynamicContainer columns={6} extend={rules.extendInnerContainer}>
                <Text type={TextTypes.EXTRA_LARGE} extend={rules.extendText}>
                    <FormattedMessage id="campaignPage.impressum.description1" />
                </Text>
            </DynamicContainer>
            <DynamicContainer columns={6} extend={rules.extendInnerContainer}>
                <Text type={TextTypes.EXTRA_LARGE} extend={rules.extendText}>
                    <FormattedMessage id="campaignPage.impressum.description2" />
                </Text>
            </DynamicContainer>

            <DynamicContainer columns={6} extend={rules.extendInnerContainer}>
                <Agreement extend={rules.extendAgreement} consents={constets} />
            </DynamicContainer>
        </DynamicContainer>
    );
};

ImpressumSection.propTypes = {
    rules: PropTypes.shape({}).isRequired,
    styles: PropTypes.shape({}).isRequired,
};

export default ImpressumSection;
