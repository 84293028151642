import { React, PropTypes, UI, FormattedMessage } from '../../dependencies';

import WorkshopImage from './WorkshopImage';

const { DynamicContainer, ContainerType, Title, TitleTypes } = UI;

const WorkshopOrder = ({ styles, rules, content, scrollRef }) => {
    return (
        <DynamicContainer type={ContainerType.FLEX} extend={rules.extendContainer} columns={12}>
            <div className={styles.descriptionContainer}>
                <Title extend={rules.extendTitle} as={TitleTypes.H2}>
                    <FormattedMessage id={content.title} />
                </Title>

                <a
                    href="https://docs.google.com/forms/d/1-c_hP9EPcsijI3iws5cUddDjhs4jH4yjTsS_5iYltMU"
                    className={styles.link}
                    target="_blank"
                    rel="noreferrer"
                >
                    <FormattedMessage id={content.cta} />
                </a>
            </div>
            <WorkshopImage src={content.image} alt={content.imageAlt} />
        </DynamicContainer>
    );
};

WorkshopOrder.propTypes = {
    styles: PropTypes.shape().isRequired,
    rules: PropTypes.shape().isRequired,
    content: PropTypes.shape().isRequired,
    scrollRef: PropTypes.shape(),
};

WorkshopOrder.defaultProps = {
    scrollRef: null,
};

export default WorkshopOrder;
