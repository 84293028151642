export const extendContainer = () => ({
    container: () => ({
        backgroundColor: `#F4F4F4`,

        marginBottom: '7.5rem',

        desktopFullHD: {
            marginBottom: '10.4375rem',
            paddingTop: '6.5rem',
            paddingBottom: '6.5rem',
        },
    }),
});

export const extendInnerContainer = () => ({
    container: () => ({
        tablet: {
            padding: '0 2.5rem',
        },
    }),
});

export const extendAgreement = () => ({
    link: () => ({
        marginTop: '2rem',
    }),
});

export const extendText = () => ({
    text: () => ({
        fontWeight: 500,
        fontSize: '0.9375rem',
        lineHeight: '1.375rem',

        tabletLarge: {
            fontSize: '0.9375rem',
            lineHeight: '1.375rem',
        },
    }),
});

export const extendTitle = () => ({
    title: ({ theme: { colors } }) => ({
        fontSize: '2.25rem',
        marginTop: '0rem',

        tablet: {
            fontSize: '3rem',
            marginTop: '0rem',
        },
    }),
});
