import { React, PropTypes, UI, FormattedMessage } from '../../dependencies';

const { Modal } = UI;

const Agreement = ({ styles, consents }) => {
    const [modalOpen, setModalOpen] = React.useState(false);

    const handleModalChange = e => {
        e.preventDefault();

        if (e.type !== 'keydown') {
            setModalOpen(!modalOpen);
            return;
        }

        setModalOpen(e.key !== 'Escape');
    };

    function createMarkup() {
        return { __html: consents };
    }

    return (
        <>
            <button
                tabIndex="0"
                onKeyDown={handleModalChange}
                type="button"
                onClick={handleModalChange}
                className={styles.link}
            >
                <FormattedMessage id="campaignPage.impressum.button1" />
            </button>
            <Modal open={modalOpen} onOpenChange={open => setModalOpen(open)}>
                {/* eslint-disable-next-line  react/no-danger*/}
                <div className={styles.dangerousContainer} dangerouslySetInnerHTML={createMarkup()} />
            </Modal>
        </>
    );
};

Agreement.propTypes = {
    styles: PropTypes.shape({
        dangerousContainer: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
    }).isRequired,
};

export default Agreement;
